import Swiper from "swiper/dist/js/swiper";

//Promotions Slider
let promotionsSliderSettings = {
  spaceBetween: 10,
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    1000: {
      slidesPerView: 2,
      loop: true,
      pagination: {
        el: ".js-promotionDots",
        clickable: true,
      },
    },
    768: {
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      slidesPerGroup: 1,
      spaceBetween: 10,
      pagination: {
        el: ".js-promotionDots",
        clickable: true,
      },
    },
  },
};

let numOfPromotionSlides =
  document.querySelectorAll(".js-promotionSlide").length;
if (numOfPromotionSlides > 1) {
  if (numOfPromotionSlides < 4) {
    promotionsSliderSettings.slidesPerView = numOfPromotionSlides;
  } else {
    promotionsSliderSettings.slidesPerView = 3;
  }
}

if (numOfPromotionSlides > 3) {
  promotionsSliderSettings.pagination = {
    el: ".js-promotionDots",
    clickable: true,
  };
}

let promotionsSlider = new Swiper(
  ".js-promotionsContainer",
  promotionsSliderSettings
);

//Gallery Slider
let gallerySliderSettings = {
  slidesPerView: 3,
  loop: false,
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    900: {
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2,
    },
    400: {
      slidesPerView: 1,
    },
  },
};

let numOfGallerySlides = document.querySelectorAll(".js-gallerySlide").length;
if (numOfGallerySlides > 1) {
  gallerySliderSettings.pagination = {
    el: ".js-galleryDots",
    clickable: true,
  };
}

let gallerySlider = new Swiper(".js-gallerySlider", gallerySliderSettings);

//Schedule Slider
let scheduleSlider = new Swiper(".js-schedule", {
  slidesPerView: 1,
  spaceBetween: 30,
  allowTouchMove: false,
  autoHeight: true,
  breakpoints: {
    1000: {
      slidesPerView: 2,
      centeredSlides: true,
      allowTouchMove: true,
      loop: false,
      spaceBetween: 10,
      pagination: {
        el: ".js-schedule-pagination",
        clickable: true,
      },
    },
    768: {
      slidesPerView: "auto",
      centeredSlides: true,
      allowTouchMove: true,
      loop: true,
      spaceBetween: 10,
      pagination: {
        el: ".js-schedule-pagination",
        clickable: true,
      },
    },
  },
});
