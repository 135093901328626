import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

var grid = document.querySelector('.masonry');
var msnry = new Masonry( grid, {
  itemSelector: '.item',
  columnWidth: 300,
  gutter: 10,
  fitWidth: true
});

imagesLoaded( grid ).on( 'progress', function() {
  msnry.layout();
});