let cartButton = document.querySelector('.js-openCart');


Snipcart.subscribe('cart.ready', () => {
  if (Snipcart.api.items.count() > 0) {
    cartButton.classList.add('active');
  }
});

Snipcart.subscribe('item.added', function (item) {
  if (Snipcart.api.items.count() > 0) {
    cartButton.classList.add('active');
  }
});

Snipcart.subscribe('cart.closed', () => {
  if (Snipcart.api.items.count() === 0) {
    cartButton.classList.remove('active');
  }
});
  
cartButton.addEventListener('click', () => {
  Snipcart.api.modal.show();
});