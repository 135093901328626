import {isAfter, addDays, differenceInCalendarDays, differenceInSeconds} from 'date-fns';

let nextRegisterDate = document.querySelector('.js-nextCycleDate');
let {startday, startmonth, startyear} = nextRegisterDate.dataset;

let cycleStartDate = new Date(startyear, startmonth, startday);
let cycleDeadlineDate = addDays(cycleStartDate, 7);
let today = new Date();

/* Handle Registration Logic */
if(isAfter(today, cycleStartDate)) {
  if(isAfter(today, cycleDeadlineDate)) {
    //Block Registration
    disableRegistration();
  } else {
    //Allow Registration but show deadline notification
    enableRegistration(0);
    enableTimer(cycleDeadlineDate);
    setMessages(true);
  }
} else {
  //Allow Registration
  let trialDays = differenceInCalendarDays(cycleStartDate, today) + 1;
  enableRegistration(trialDays);
  enableTimer(cycleStartDate);
  setMessages(false);
}

function enableRegistration(trialPeriodInDays) {
  let registerButtons = document.querySelectorAll('.js-registerBtn');
  for(let button of registerButtons) {
    button.dataset.itemPaymentTrial = trialPeriodInDays;
  }
}

function disableRegistration() {
  let cycleDate = document.querySelector('.js-nextCycleDate');
  let cycleInfo = document.querySelector('.js-cycleInfo');
  let timerBody = document.querySelector('.js-timer-body');
  let registerButtons = document.querySelectorAll('.js-registerBtn');

  cycleInfo.innerHTML = `Registrations for the <strong>${cycleDate.textContent}</strong> cycle are currently closed. Please check back later.`;
  timerBody.innerHTML = `<p></p>`;
  for(let button of registerButtons) {
    button.disabled = true;
  }
}

/* Cycle Start Timer */
function enableTimer(endDate) {
  let timer = document.querySelector('.js-timer');

  setInterval(() => {
    let currentTime = new Date();
    let timeDiff = differenceInSeconds(endDate, currentTime);
  
    let diffDays = Math.floor(timeDiff/(60*60*24));
    let diffHours = Math.floor((timeDiff % ( 60 * 60 * 24)) / ( 60 * 60));
    let diffMinutes = Math.floor((timeDiff % ( 60 * 60)) /60);
    let diffSeconds = Math.floor(timeDiff % 60);
    timer.innerHTML = `<strong>${diffDays}d ${diffHours}h ${diffMinutes}m ${diffSeconds}s </strong>`;
    
  }, 1000);
}

function setMessages(cycleStarted) {
  let cycleDate = document.querySelector('.js-nextCycleDate');
  let cycleInfo = document.querySelector('.js-cycleInfo');

  if (!cycleStarted) {
    cycleInfo.innerHTML = `The next cycle begins on <strong>${cycleDate.textContent}</strong>`;
  } else {
    cycleInfo.innerHTML = `There's still time to register for the <strong>${cycleDate.textContent}</strong> cycle`;
  }
}