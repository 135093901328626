import {isAfter} from 'date-fns';

let promotionBtn = document.querySelector('.js-promotionBtn');
let expirationDate = promotionBtn.dataset.expiration;

if (expirationDate) {
  let [month,day,year] = expirationDate.split('-');
  expirationDate = new Date(year,month-1,day);
  let today = new Date();
  
  if (isAfter(today, expirationDate)) {
    promotionBtn.disabled = true;
    promotionBtn.textContent = 'Sale Ended';
  }
}