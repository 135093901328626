import {addMonths, getMonth, getYear, isAfter, differenceInCalendarDays} from 'date-fns';

let today = new Date();
let currentMonth = getMonth(addMonths(today, 1));
let currentYear = getYear(today);
let collectDay = document.querySelector('.js-collectDay').dataset.collectDay;

let cycleStartDate = new Date(currentYear, currentMonth, collectDay);

/* Handle Registration Logic */
let trialDays = differenceInCalendarDays(cycleStartDate, today);
//enableRegistration(trialDays);

function enableRegistration(trialPeriodInDays) {
  let teamRegister = document.querySelector('.js-teamRegister');
  teamRegister.dataset.itemPaymentTrial = trialPeriodInDays;
}