let navToggle = document.querySelector('.js-mobileNavToggle');
let mobileNav = document.querySelector('.js-mobileNav');
let mobileDropdownToggles = document.querySelectorAll('.js-mobileNav .toggle');

navToggle.addEventListener('click', function(){
  navToggle.classList.toggle('active');
  mobileNav.classList.toggle('active');
});

for (let mobileToggle of mobileDropdownToggles) {
  mobileToggle.addEventListener('click', function(){
    let dropdownParent = mobileToggle.parentNode.parentNode;
    dropdownParent.classList.toggle('active');
    if(dropdownParent.classList.contains('active')){
      mobileToggle.textContent = '-';
    }else{
      mobileToggle.textContent = '+';
    }
  });
}