import Swiper from "swiper/dist/js/swiper";

//Schedule Slider
let scheduleSlider = new Swiper(".js-schedule", {
  slidesPerView: 3,
  spaceBetween: 30,
  allowTouchMove: false,
  breakpoints: {
    1000: {
      slidesPerView: 2,
      allowTouchMove: true,
      loop: false,
      spaceBetween: 10,
      pagination: {
        el: ".js-schedule-pagination",
      },
    },
    768: {
      slidesPerView: 1,
      allowTouchMove: true,
      loop: true,
      spaceBetween: 10,
      pagination: {
        el: ".js-schedule-pagination",
      },
    },
  },
});
