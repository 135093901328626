import Swiper from 'swiper/dist/js/swiper';

/* TShirt Slider */
let tshirtSlider = new Swiper('.js-tshirtSlider', {
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints:{
    1000:{
      slidesPerView: 2,
      allowTouchMove: true,
      loop: false,
      spaceBetween: 10
    },
    768: {
      slidesPerView: 1,
      allowTouchMove: true,
      loop: true,
      spaceBetween: 10
    }
  }
});

/* Store Navigation */
let storeNavLinks = document.querySelectorAll('.js-storeNavLink');
for (let link of storeNavLinks) {
  link.addEventListener('click', () => {
    let index = link.dataset.navIndex;
    let activeLink = document.querySelector('.js-storeNavLink.active');
    let activePage = document.querySelector('.js-storePage.active');

    //Deactivate Current Selected
    activePage.classList.remove('active');
    activeLink.classList.remove('active');

    //Activate Newly Selected
    link.classList.add('active');
    document.querySelector(`.js-storePage-${index}`).classList.add('active');
  });
}
/* Form Logic */
let forms = document.querySelectorAll('.js-storeform');

for (let form of forms) {
  let formSelects = form.querySelectorAll('select');
  let formInputs = form.querySelectorAll('input');

  for(let select of formSelects) {
    setSnipButton(form, select);

    select.addEventListener('change', () => {
      setSnipButton(form, select);
    });
  }

  for(let input of formInputs) {
    setSnipButton(form, input);
    input.addEventListener('change', () => {
      setSnipButton(form, input);
    });
  }
}

function setSnipButton(form, select) {
  let productID = select.dataset.product;
  let value = select.value;
  let index = select.dataset.index;

  let button = form.querySelector(`[data-item-id="${productID}"]`);
  button.setAttribute(`data-item-custom${index}-value`, value);
}
