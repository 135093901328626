let loginBtns = document.querySelectorAll('.js-login');
let snipPopup = document.querySelector('.snip-layout.snip-active');

for ( let loginBtn of loginBtns ) {
  loginBtn.addEventListener('click', () => {
    let snipPopup = document.querySelector('.snip-layout');
    let items = Snipcart.api.items.all();
    for (let item of items) {
      if (item.id === 'create-online-account') {
        snipPopup.classList.remove('register');
        snipPopup.classList.add('login');
      }
    }
  });
}

Snipcart.subscribe('item.added', function (item) {
  let snipPopup = document.querySelector('.snip-layout.snip-active');
  if (item.id === 'create-online-account') {
    snipPopup.classList.remove('login');
    snipPopup.classList.add('register');
  }
});